// for localhost
//export const API = 'http://localhost:5000'

//Jenkins CICD
// export const API = 'https://reinventapi.checkapp.one'

//Latest
// export const API = 'http://185.100.234.214:4000'
// export const API = 'http://localhost:5000'
export const API = 'https://api.reinventuniforms.com'

//old
// export const API = 'https://api.reinventuniforms.in'
